// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from 'src/environments/environment.interface';

export const environment: IEnvironment = {
    production: false,
    environmentName: 'staging',
    splashScreen: true,
    api: {
        basicToken: '9c1185a5c5e9fc54612808977ee8f548b2258d31',
        baseUrl: 'https://staging.buix-api.moveup.media',
    },
    httpDelay: 400,
    debug: {
        showFormDump: false,
    },
    wordpressDemoUrl: 'https://mi-apuesta.com/mantosnew',
    wordpressShortcodeDemoUrl: 'https://mi-apuesta.com/mantosnew/demo-shortcode',
    bypassCustomGuards: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error'; // Included with Angular CLI.
