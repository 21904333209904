import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AddEditGameDto,
    GameFiltersDto,
    GameResponseDto,
} from 'src/app/core/services/moveup-api/game/game.dtos';
import { Pagination } from 'src/app/shared/dtos/pagination.dtos';
import { dtoToHttpParamsV2 } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class GameService {
    baseUrl = `${environment.api.baseUrl}/admin/game`;

    constructor(private http: HttpClient) {}

    getList(
        page: number,
        limit: number,
        dto: GameFiltersDto,
    ): Observable<Pagination<GameResponseDto>> {
        return this.http.get<Pagination<GameResponseDto>>(`${this.baseUrl}/list/${page}/${limit}`, {
            params: dtoToHttpParamsV2(dto),
        });
    }

    getFormInfos(id: string): Observable<AddEditGameDto> {
        return this.http.get<AddEditGameDto>(`${this.baseUrl}/form-infos/${id}`);
    }

    delete(id: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${id}`);
    }

    addGame(dto: AddEditGameDto): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}`, dto);
    }

    editGame(id: string, dto: AddEditGameDto): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/${id}`, dto);
    }

    getOdds(id: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/odds/${id}`);
    }
}
